import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { PROTEC8_PATH } from '../../../app.interface';
import { AUTH_PATH } from '@origin8-web/core-ui/login/routes';
import { APP_PERMS, APP_ROLES } from 'common.interfaces';
import { RouterModule } from '@angular/router';
import { OnlyPermissionsDirective } from '@origin8-web/core-ui/login/permissions';
import { WINDOW } from '@origin8-web/core-ui/shared-providers';

@Component({
  selector: 'navbar-menu-dropdown',
  templateUrl: './navbar-menu-dropdown.component.html',
  styleUrls: ['./navbar-menu-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [RouterModule, OnlyPermissionsDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarMenuDropdownComponent {
  readonly window = inject(WINDOW);
  links = {
    dashboard: PROTEC8_PATH.DASHBOARD,
    warmTransfer: PROTEC8_PATH.WARM_TRANSFER,
    quotes: PROTEC8_PATH.QUOTES,
    logout: AUTH_PATH.LOGOUT,
    impersonation: AUTH_PATH.IMPERSONATION,
    releases: PROTEC8_PATH.RELEASES,
    advocateScheduleAdherence: PROTEC8_PATH.ADVOCATE_SCHEDULE_ADHERENCE,
    allCallScripts: PROTEC8_PATH.CALL_SCRIPT_ALL,
  };
  impersonationPerms = [APP_PERMS.USER_PROFILE_READ_ALL, APP_PERMS.PIPELINE_READ_ALL];
  betaTesterRoles = [
    APP_ROLES.ADMIN,
    APP_ROLES.MENTOR,
    APP_ROLES.TRAINER,
    APP_ROLES.DEVELOPER,
    APP_ROLES.BETA_TESTER,
    APP_ROLES.BOARD,
  ];
  advocateScheduleAdherencePerms = [APP_PERMS.PIPELINE_READ_ALL, APP_PERMS.ADVOCATE_PAID_TIME_READ_ALL];
  callScriptPerms = [APP_PERMS.CALL_SCRIPT_READ_ALL];

  openWtPage() {
    const options = 'width=320,height=300,left=100,top=100';
    this.window.open(PROTEC8_PATH.INCOMING_WARM_TRANSFER, 'wtPopup', options);
  }
}
