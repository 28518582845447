<div class="app-navbar">
  <div>
    <img
      routerLink=""
      alt="origin8cares logo"
      class="navbar-img"
      src="/assets/origin8-logo-dark.png"
      e2e-id="navbar-o8-logo-link"
      (click)="closeMenu()"
    />
  </div>
  <div class="navbar-spacer-vertical"></div>
  <div class="o8-subhead">{{ appName }}</div>
  <div class="navlinks-container nav-bar-desktop">
    <div class="nav-link-item">
      <a [routerLink]="links.dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        ><o8-icon class="centered-vertical" [icon]="Origin8Icon"></o8-icon>
        <div class="centered-vertical margin-left-5px">Home</div></a
      >
    </div>
    <!-- <div class="nav-link-item">
      <a [routerLink]="links.warmTransfer" routerLinkActive="active">Warm Transfer</a>
    </div> -->
    <!-- <div class="nav-link-item">
      <a [routerLink]="links.quotes" routerLinkActive="active">Quotes</a>
    </div> -->
  </div>
  <div class="nav-link-item navbar-mobile-menu">
    @if (isMenuOpen) {
      <navbar-menu-dropdown (click)="closeMenu()"></navbar-menu-dropdown>
    }
    @if (!isMenuOpen) {
      <div (click)="openMenu()" class="menu-button">
        <o8-icon [icon]="menuIcon"></o8-icon>
      </div>
    }

    @if (isMenuOpen) {
      <div (click)="closeMenu()" class="menu-button">
        <o8-icon [icon]="closeIcon" (click)="closeMenu()"></o8-icon>
      </div>
    }
  </div>
</div>
